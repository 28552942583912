<template>
  <div class="copyright-box">
    <p>© 2021 Kosaku Namikawa</p>
  </div>
</template>

<script>
export default {
  name: "SectionCopyright"
}
</script>

<style scoped>
.copyright-box {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #828384;
}
</style>
