<template>
<h2 class="section-title"><slot></slot></h2>
</template>

<script>
export default {
name: "ElementSectionTitle"
}
</script>

<style scoped>
.section-title{
  /*font-family: ;*/
  font-size: 2em;
  margin: 1em 0;
}
</style>
