<template>
  <div class="top-container">
    <h1>Kosaku Namikawa</h1>
  </div>
</template>

<script>
export default {
  name: "SectionTop"
}
</script>

<style scoped>
.top-container {
  height: 50%;
  width: 100%;
  margin: 40vh 0;
}

h1 {
  font-size: 3em;
}
</style>
