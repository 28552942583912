<template>
  <div class="publication-list">
    <p>Kenta Yamamoto, Ippei Suzuki,
      <u>Kosaku Namikawa</u>, Kaisei Sato, and Yoichi Ochiai. 2021 Interactive Eye Aberration Correction for Holographic Near-Eye Display. In Proceedings of the Augmented Humans (AHs) International Conference 2021. (to appear)

      <a class="project-link"
          href="https://digitalnature.slis.tsukuba.ac.jp/2021/02/interactive-eye-aberration-correction-for-hned/">Project Page</a>
    </p>
    <p>
      <u>Namikawa K.</u>, Suzuki I., Iijima R., Sarcar S., Ochiai Y. (2021) EmojiCam: Emoji-Assisted Video Communication System Leveraging Facial Expressions. In: Kurosu M. (eds) Human-Computer Interaction. Design and User Experience Case Studies. HCII 2021. Lecture Notes in Computer Science, vol 12764. Springer, Cham.
      <a href="https://doi.org/10.1007/978-3-030-78468-3_42">https://doi.org/10.1007/978-3-030-78468-3_42</a>

      <a class="project-link"
          href="https://digitalnature.slis.tsukuba.ac.jp/2021/07/emojicam-hcii2021/">Project Page</a>
    </p>
    <p>
      Kenta Yamamoto,
      <u>Kosaku Namikawa</u>, and Yoichi Ochiai. 2021. TeraFoils: Design and Rapid Fabrication Techniques for Binary Holographic Structures in the Terahertz Region. In ACM SIGGRAPH 2021 Poster (SIGGRAPH ’21). ACM, New York, NY, USA, Article 3, 2 pages. DOI:
      <a href="https://doi.org/10.1145/3450618.3469157">https://doi.org/10.1145/3450618.3469157</a> (to appear)

      <a class="project-link"
          href="https://digitalnature.slis.tsukuba.ac.jp/2021/07/terafoils-publication/">Project Page</a>
    </p>
    <p>
      Nozomu Yoshida,
      <u>Kosaku Namikawa</u>, Yusuke Koroyasu, Yoshiki Nagatani, and YoichiOchiai. 2021. Auditory-Centered Vocal Feedback System Using Solmization forTraining Absolute Pitch Without GUI. InHuman-Computer Interaction – INTERACT2021, Carmelo Ardito, Rosa Lanzilotti, Alessio Malizia, Helen Petrie, Antonio Pic-cinno, Giuseppe Desolda, and Kori Inkpen (Eds.). Springer International Publishing,Cham, 3–19.
      <a class="project-link" href="https://digitalnature.slis.tsukuba.ac.jp/2021/08/vocal_feedback/">Project Page</a>
    </p>
    <p>
      Masaaki Sadasue, Ippei Suzuki,
      <u>Kosaku Namikawa</u>, Kengo Tanaka, Chieko Nishimura, Masaki Okamura, and Yoichi Ochiai. (2022). Development and Evaluation of Systems to Enjoy a Wedding Reception for People with Low Vision. In: Stephanidis, C., Antona, M., Ntoa, S. (eds) HCI International 2022 Posters. HCII 2022. Communications in Computer and Information Science, vol 1580. Springer, Cham.
      <a href="https://doi.org/10.1007/978-3-031-06417-3_79">https://doi.org/10.1007/978-3-031-06417-3_79</a>
    </p>
    <p>
      Kana YAMAGUCHI,
      <u>Kosaku NAMIKAWA</u>, Yoshiki NAGATANI, and Yoichi OCHIAI. 2022. Impression of the sound of rain hitting umbrellas. Proceedings of the 24th International Congress on Acoustics (October 2022), ABS-0569-.
    </p>
  </div>
</template>

<script>
export default {
  name: "ElementPublicationList"
}
</script>

<style scoped>
.publication-list .project-link {
  opacity: 60%;
}

.publication-list p {
  margin: 10px 0;
}

</style>
